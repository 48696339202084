import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

const PageTitle = ({ pageTitle }) => {
	const { headingSize, pageTitleClasses } = usePrefs();
	return (
		<h1 className={setClassNames([headingSize, pageTitleClasses])}>
			{pageTitle}
		</h1>
	);
};

PageTitle.propTypes = {
	pageTitle: PropTypes.string.isRequired
};

export default PageTitle;
