import { useLabels } from 'wsm-common-data';
import { useSelector } from 'react-redux';

const removeTags = (str) => {
	if (!str) return '';
	const strDecode = decodeURIComponent(str);
	const strNoQuotes = strDecode.replace('"', '');
	const strNoNewLine = strNoQuotes.replace('\n', '');

	// Regular expression to identify HTML tags in
	// the input string. Replacing the identified
	// HTML tag with a null string.
	return strNoNewLine.replace(/(<([^>]+)>)/gi, '');
};

export default function usePageTitle() {
	const labels = useLabels();
	const pageTitleLabel = useSelector(
		(state) => state?.pageInfo?.pageTitleLabel
	);
	const pageTitleStr = labels.get(pageTitleLabel);
	const pageTitle = removeTags(pageTitleStr);

	return pageTitle;
}
