import React from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import logger from 'ws-scripts/modules/logger';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { ErrorBoundary } from 'wsm-error-boundary';
import { useFlags } from 'wsm-common-data';
import PageTitle from '../components/PageTitle';
import usePageTitle from '../hooks/usePageTitle';

const Widget = () => {
	const pageTitle = usePageTitle();

	const flags = useFlags();

	const disableSSR = !flags['ws-inv-page-title-server-render'];

	if (disableSSR) {
		if (isBrowser) {
			const { pageTitle: pageTitleClient } =
				window.DDC.WidgetData['inventory-data-bus1'].props;

			return (
				<ErrorBoundary
					errorHandler={(error, errorInfo) => {
						setNewRelicCustomAttribute(
							'SRP ERROR',
							`ws-inv-page-title error boundary.\n${error}`
						);
						const newError = new Error(
							`ws-inv-page-title error boundary.\n${error}`
						);
						newError.originalError = error;
						newError.originalStackTrace = errorInfo.componentStack;
						logger.error(
							`${newError}\n${newError.originalStackTrace}`
						);
					}}
				>
					<PageTitle pageTitle={pageTitleClient} />
				</ErrorBoundary>
			);
		} else {
			return null;
		}
	}

	return (
		<ErrorBoundary
			errorHandler={(error, errorInfo) => {
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-page-title error boundary.\n${error}`
				);
				const newError = new Error(
					`ws-inv-page-title error boundary.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			<PageTitle pageTitle={pageTitle} />
		</ErrorBoundary>
	);
};

export default Widget;
