import { SET_PAGE_INFO } from '../actions/types';

export default function pageInfo(state = {}, action) {
	switch (action.type) {
		case SET_PAGE_INFO:
			return { ...action.payload };
		default:
			return state;
	}
}
